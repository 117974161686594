import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
  Account,
  Address,
  ChecklistValues,
  FulfillmentOption,
  getDaysSinceShopCreation,
  PlanDiscount,
  Shop
} from '@castiron/domain';
import {accountRepository, shopRepository, tierRepository} from '../../domain';
import {handleReducerError} from './errorHandler';
import firebase from 'firebase/compat/app';
import _ from 'lodash';
import {getService} from '../../firebase';

const getAccountStatusService = getService('accounts', 'getaccountstatusv2');
const createShopService = getService('shops', 'createshopv2');
const updateShopService = getService('shops', 'updateShop');
const checkShopUrlsService = getService('shops', 'checkshopurls');
const getPlanDiscountService = getService('subscriptions', 'getplandiscount');
const updatePayoutSettingsService = getService('stripe', 'updatepayoutsettings');

export type StripeStatus = 'READY' | 'INCOMPLETE' | 'NOT_CONNECTED' | 'PROCESSING' | 'ERROR';

export interface StripeState {
  stripeLink?: string;
  status: StripeStatus;
}

export type UserState =
  | 'newUser'
  | 'inTrial'
  | 'legacyInTrial'
  | 'legacyNewSubscriber'
  | 'legacyTrialCompleted'
  | 'currentSubscriber'
  | 'trialExpired'
  | 'subscriptionEnded'
  | 'cancellingSubscriber';

export interface ShopState {
  shop: Shop;
  fulfillments: FulfillmentOption[];
  account: Account;
  blockedWebsiteUrls: string[];
  stripe?: StripeState;
  loading: boolean;
  error: string;
  formRef: any;
  redirectUrl: string;
  stripeRefresh: boolean;
  passwordProtectedMessage?: string;
  hasLoaded: boolean;
  isOnboarding: boolean;
  fromOnboarding: boolean;
  userState?: UserState;
  discount?: PlanDiscount;
}

const initialState: ShopState = {
  shop: null,
  fulfillments: [],
  account: null,
  redirectUrl: '',
  stripeRefresh: false,
  blockedWebsiteUrls: [],
  formRef: null,
  loading: false,
  error: '',
  hasLoaded: false,
  isOnboarding: sessionStorage.getItem('isOnboarding') === 'true',
  fromOnboarding: false,
};

const determineUserState = (account: Account) => {
  let userState: UserState;
  if (account.hasLegacySubscription()) {
    if (account.isLegacyTrialCompleted()) {
      userState = 'legacyTrialCompleted';
    } else if (account.isInTrial()) {
      userState = 'legacyInTrial';
    } else {
      userState = 'legacyNewSubscriber';
    }
  } else {
    if (account.isNewUnsubscribedUser()) {
      userState = 'newUser';
    } else if (account.isInTrial()) {
      userState = 'inTrial';
    } else if (account.isTrialCompleted()) {
      userState = 'trialExpired';
    } else if (account.isPaidSubscriptionEnded()) {
      userState = 'subscriptionEnded';
    } else {
      account?.subscription?.status === 'pending-canceled'
        ? (userState = 'cancellingSubscriber')
        : (userState = 'currentSubscriber');
    }
  }
  return userState;
};

const getShop = async (id: string) => {
  const accountStatusPromise = getAccountStatusService({});
  const shopPromise = shopRepository.get(id);

  const [accountStatus, shop] = await Promise.all([accountStatusPromise, shopPromise]);

  const fulfillments = await shop?.getFulfillmentOptions();

  const status = accountStatus || {};

  return {
    shop,
    fulfillments: (fulfillments || []),
    ...status,
    account: accountRepository.bless(accountStatus?.account),
  };
};

const createShop = async (shop: any) => {
  const referralCode = sessionStorage.getItem('friendbuy-referralCode');
  const integrations = !!referralCode
    ? {
        friendBuy: {
          referralCode,
        },
      }
    : undefined;
  const response = await createShopService({ ...shop, integrations });

  return response;
};

const updateShop = async ({
  shop,
  newCompletions,
}: {
  shop: Shop;
  newCompletions?: ChecklistValues[];
}): Promise<Shop> => {
  const existingCompletions = shop.checklistCompletions;
  const newShop = { ...shop };
  delete newShop.checklistCompletions;
  delete newShop.canAcceptPayments;
  delete newShop.createdAt;
  delete newShop.updatedAt;
  delete newShop.hasEverLaunched;
  if (newCompletions) {
    await shopRepository.updateProps(shop.id, {
      checklistCompletions: firebase.firestore.FieldValue.arrayUnion(...newCompletions),
    });
  }
  await shopRepository.update(newShop);

  return {
    ...shop,
    checklistCompletions: _.union(existingCompletions, newCompletions),
  };
};

const updateChecklist = async ({ shop, items }: { shop: Shop; items: ChecklistValues[] }): Promise<Shop> => {
  await shopRepository.updateProps(shop.id, {
    checklistCompletions: firebase.firestore.FieldValue.arrayUnion(...items),
  });

  return {
    ...shop,
    checklistCompletions: _.union(shop.checklistCompletions, items),
  };
};

const updateAccountBillingAddress = async ({
  account,
  billingAddress,
}: {
  account: Account;
  billingAddress: Address;
}): Promise<Account> => {
  await accountRepository.updateProps(account.id, {
    billingAddress: billingAddress,
  });

  return {
    ...account,
  };
};

const apiUpdateShop = async ({ id, props }): Promise<Shop> => {
  const response = await updateShopService(props);
  const updatedShop = await shopRepository.get(id);
  return updatedShop;
};

const getWebsiteUrls = async () => {
  const response = await checkShopUrlsService({});
  return response;
};

const launchShop = async (id: string): Promise<void> => {
  await shopRepository.updateProps(id, {
    status: 'active',
    checklistCompletions: firebase.firestore.FieldValue.arrayUnion(ChecklistValues.GoLive),
  });
};

const convertToDefaultTier = async (shopId: string) => {
  const shop = await shopRepository.get(shopId);
  const account = await accountRepository.get(shopId);
  const DEFAULT_TIER_ID = 'ktIzir4hddvs7EcIJ75q';
  const defaultTier = await tierRepository.get(DEFAULT_TIER_ID);
  await accountRepository.updateProps(shopId, {
    tier: _.pick(defaultTier, ['id', 'name', 'castironTakeRate', 'payoutFrequency']),
  });
  await shopRepository.updateProps(shopId, {
    ...(shop?.statusAtCancel ? { status: shop.statusAtCancel } : {}),
    'paymentSettings.castironTakeRate': defaultTier.castironTakeRate,
    'paymentSettings.customerRate': defaultTier.castironTakeRate,
    'paymentSettings.isCustomerPayingStripeFee': true,
  });
  const shopCreatedWithinTwoWeeks = getDaysSinceShopCreation(account?.createdAt) < 14;
  updatePayoutSettingsService({
    settings: {
      payouts: {
        schedule: {
          interval: shopCreatedWithinTwoWeeks ? 'weekly' : defaultTier.payoutFrequency,
          delay_days: shopCreatedWithinTwoWeeks ? 7 : "minimum",
          weekly_anchor: shopCreatedWithinTwoWeeks || defaultTier.payoutFrequency === 'weekly' ? 'monday' : undefined,
        },
      },
    },
  });
};

const getPlanDiscount = async (code: string): Promise<PlanDiscount> =>
  code ? getPlanDiscountService({ code }) : undefined;

export const getShopAction = createAsyncThunk('shops/getShop', getShop);
export const createShopAction = createAsyncThunk('shops/createShop', createShop);
export const updateShopAction = createAsyncThunk('shops/updateShop', updateShop);
export const updateStoreShopAction = createAsyncThunk('shops/updateStoreShop', updateShop);
export const getWebsiteUrlsAction = createAsyncThunk('shops/getWebsiteUrls', getWebsiteUrls);
export const apiUpdateShopAction = createAsyncThunk('shops/apiUpdateShop', apiUpdateShop);
export const updateAccountBillingAddressAction = createAsyncThunk(
  'shops/updateAccountBillingAddress',
  updateAccountBillingAddress,
);
export const launchShopAction = createAsyncThunk('shops/launchShop', launchShop);
export const updateChecklistAction = createAsyncThunk('shops/updateChecklist', updateChecklist);

export const setDiscountAction = createAsyncThunk('shops/getPlanDiscount', getPlanDiscount);

export const convertToDefaultTierAction = createAsyncThunk('shops/convertToDefaultTier', convertToDefaultTier);

const stripeStatusFromAccount = (account: Account): StripeStatus =>
  account.stripeAccountId && account.isReady
    ? 'READY'
    : account.stripeAccountId && !account.isReady
    ? 'INCOMPLETE'
    : 'NOT_CONNECTED';

const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    setRedirectUrl(state, action) {
      console.log('setRedirectUrl : ', action);
      state.redirectUrl = action.payload;
    },
    setStripeRefresh(state, action) {
      console.log('setRedirectUrl : ', action);
      state.stripeRefresh = action.payload;
    },
    setIsOnboarding(state, action) {
      sessionStorage.setItem('isOnboarding', action.payload);
      state.isOnboarding = action.payload;
    },
    setFromOnboarding(state, action) {
      state.fromOnboarding = action.payload;
    },
    setAccount(state, action) {
      state.account = action.payload;
      const stripeStatus = stripeStatusFromAccount(action.payload);
      state.stripe = state.stripe
        ? {
            stripeLink: state.stripe.stripeLink,
            status: stripeStatus,
          }
        : {
            status: stripeStatus,
          };
      state.userState = determineUserState(action.payload);
    },
    setShop(state, action) {
      state.shop = action.payload;
    },
  },
  extraReducers: builder => {
    // you can mutate state directly, since it is using immer behind the scenes\
    builder
      .addCase(getShopAction.pending, state => {
        state.loading = true;
      })
      .addCase(getShopAction.fulfilled, (state, action) => {
        state.shop = action.payload.shop;
        state.fulfillments = action.payload.fulfillments;
        state.account = action.payload.account;
        state.stripe = {
          stripeLink: action.payload.stripeLink,
          status: action.payload.status,
        };
        state.hasLoaded = true;
        state.loading = false;
        state.userState = determineUserState(action.payload.account);
      })
      .addCase(getShopAction.rejected, handleReducerError('Error Getting Shop'))
      .addCase(createShopAction.pending, state => {
        state.loading = true;
      })
      .addCase(createShopAction.fulfilled, (state, action) => {
        state.shop = shopRepository.bless(action.payload.shop);
        state.account = accountRepository.bless(action.payload.account);
        state.stripe = {
          stripeLink: action.payload.stripeLink,
          status: action.payload.stripeStatus,
        };
        state.hasLoaded = true;
        state.loading = false;
        state.userState = determineUserState(action.payload.account);
      })
      .addCase(createShopAction.rejected, handleReducerError('Error Getting Shop'))
      .addCase(updateShopAction.pending, state => {
        state.loading = true;
      })
      .addCase(updateShopAction.fulfilled, (state, action) => {
        state.shop = action.payload;
        state.loading = false;
      })
      .addCase(updateShopAction.rejected, handleReducerError('Error Updating Shop'))
      .addCase(updateStoreShopAction.fulfilled, (state, action) => {
        state.shop = action.payload;
      })
      .addCase(getWebsiteUrlsAction.pending, state => {
        state.loading = true;
      })
      .addCase(getWebsiteUrlsAction.rejected, handleReducerError('Error Updating Shop'))
      .addCase(getWebsiteUrlsAction.fulfilled, (state, action) => {
        // @ts-ignore
        state.blockedWebsiteUrls = action.payload;
        state.loading = false;
      })
      .addCase(apiUpdateShopAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(apiUpdateShopAction.fulfilled, (state, action) => {
        state.shop = action.payload;
        state.loading = false;
      })
      .addCase(apiUpdateShopAction.rejected, handleReducerError('Error Updating Shop'))
      .addCase(updateChecklistAction.fulfilled, (state, action) => {
        state.shop = action.payload;
      })
      .addCase(updateChecklistAction.rejected, handleReducerError('Error Updating Shop'))
      .addCase(launchShopAction.fulfilled, (state, action) => {
        const shop = state.shop;
        state.shop = {
          ...shop,
          status: 'active',
          checklistCompletions: _.union(shop.checklistCompletions, [ChecklistValues.GoLive]),
        };
      })
      .addCase(launchShopAction.rejected, handleReducerError('Error Launching Shop'))
      .addCase(convertToDefaultTierAction.rejected, handleReducerError('Error Converting to Default Tier'))
      .addCase(setDiscountAction.fulfilled, (state, action) => {
        state.discount = action.payload;
        if (action.payload) {
          sessionStorage.setItem('discountCode', action.payload.code);
        } else {
          sessionStorage.removeItem('discountCode');
        }
      })
      .addCase(setDiscountAction.rejected, handleReducerError('Error Getting Plan Discount'));
  },
});

export const {
  setRedirectUrl,
  setStripeRefresh,
  setIsOnboarding,
  setFromOnboarding,
  setShop,
  setAccount,
} = shopSlice.actions;

export default shopSlice.reducer;
