import firebase from 'firebase/compat/app';
import _firestore from '@google-cloud/firestore';
import {Category, FeaturedIn, ShopRelatedDocument, ShopRelatedRepository} from '../shop';
import {TaxonomyCategory} from '../taxonomy';
import {Asset} from '../asset/model';
import {InputField, SelectedInputFieldValue} from '../inputfield/model';
import {FieldFunctions} from '../base/repository';
import {ProductSeoMetadata} from './model';
import {TimePeriod} from '../shop/model';
import {getProductStatus} from './utils';
import {Address} from '../address';
import esSchema from './product.es.schema.json';
import { Indexable } from '../base/indexable';
import { EventAttendee } from '../order';

export const productEsSchema = esSchema;

export const shopVisibleStatuses = ['active', 'inactive'];

export type ProductType = 'standard' | 'custom' | 'event' | 'invoice';
export type ProductPageContext = 'products' | 'order-forms' | 'events';
export const productTypeDisplayName = (productType: ProductType): string => {
  switch (productType) {
    case 'standard':
      return 'Instant Checkout';
    case 'custom':
    default:
      return 'Order Form';
  }
};

export type ProductStatus = 'active' | 'inactive' | 'deleted' | 'hidden' | 'archived';
export type ProductSource = 'onboarding' | '';

export interface Metrics {
  numSold: number;
  totalRevenue: number;
}

export interface ProductFulfillment {
  pickup: boolean;
  delivery: boolean;
  shipping: boolean;
}

export interface ProductGmcMetadata {
  title?: string;
  description?: string;
  images?: Asset[];
}

interface GoogleMerchantCenter {
  id?: string;
}

export interface Approval {
  reviewed: boolean;
  approved?: boolean;
  reviewedOn?: number;
}

export interface WebflowIntegration {
  productCollectionId: string;
}

export interface ProductIntegrations {
  webflow?: WebflowIntegration;
}

export interface BaseProduct extends ShopRelatedDocument<BaseProduct> {
  sellerId?: string; // if the seller is different from the shop, i.e. for Mark's Favorites or the Castiron shop
  type: ProductType;
  title: string;
  description?: string;
  category?: Category;
  fulfillment?: ProductFulfillment;
  images?: Asset[];
  allergen?: string[];
  dietary?: string[];
  unlimitedInventory?: boolean;
  variations?: InputField[];
  communityPackageId?: string; //legacy
  metrics?: Metrics;
  status: ProductStatus;
  productTemplateId?: string;
  seoMetadata?: ProductSeoMetadata;
  source?: ProductSource;
  googleMerchantCenter?: GoogleMerchantCenter;
  /*
   * deprecated, DO NOT USE going forward
   * imageObj: will still be used going forward for existing references,
   *   and be set as the first image in the image array on product saves
   */
  image?: string;

  imageObj?: Asset;
  schedule?: TimePeriod;
  isFeatured?: boolean;
  taxonomy?: TaxonomyCategory[];
  eventTags?: string[];
  gmcMetadata?: ProductGmcMetadata;
  featuredIn?: FeaturedIn;
  approval: Approval;
  integrations?: ProductIntegrations;
}

export interface Product extends BaseProduct {
  type: 'standard';
  price: number;
  inventory?: number;
  maximumInventory?: number;
}

export interface CustomProduct extends BaseProduct {
  type: 'custom';
  startingPrice?: number;
  minimum?: string;
  policies?: string;
}

export interface TicketedEvent extends BaseProduct {
  type: 'event';
  eventDetails: EventDetails;
  price: number;
  inventory?: number;
  maximumInventory?: number;
}

export interface EventDetails {
  date: {
    startTime: number;
    endTime: number;
  };
  // ticketsSold: number;
  location?: {
    address?: Address;
    name?: string;
    notes?: string;
    meetingUrl?: string;
  };
  sendReminderEmail?: boolean;
  reminderEmailSent?: boolean;
}

export interface CartProduct {
  id: string;
  product?: BaseProduct;
  selections?: SelectedInputFieldValue[];
  quantity: number;
  presaleId?: string;
  fromPage?: string | string[];
  attendees?: EventAttendee[];
  eventDetails?: EventDetails;
}

export interface ProductShopData {
  id: string;
  businessName: string;
  owner: string;
  websiteUrl: string;
  description: string;
  aboutTitle: string;
}

export interface ProductSearchResult {
  id: string;
  type: ProductType;
  title: string;
  description?: string;
  category?: Category;
  taxonomy?: {
    top: string[];
    mid: string[];
    leaf: string[];
    all: string[];
  };
  businessAddress?: Address;
  shop?: ProductShopData;
  serviceArea?: string[];
  fulfillment?: ProductFulfillment;
  allergen?: string[];
  dietary?: string[];
  status: ProductStatus;
  images: Asset[];
  price: number;
  specials: string[];
}

type PropType<TObj, TProp extends keyof TObj> = TObj[TProp];

export class ProductRepository extends ShopRelatedRepository<BaseProduct> {
  constructor(firestore: firebase.firestore.Firestore | _firestore.Firestore, fieldFunctions?: FieldFunctions) {
    super(firestore, 'products', fieldFunctions);
  }

  public async findActive(shopId: string, timeZone: string): Promise<BaseProduct[]> {
    return this.find({
      where: [this.whereShopIs(shopId)],
      orderBy: [{field: 'title', direction: 'asc'}],
    }).then(products => products.filter(p => getProductStatus(p, timeZone) === 'active'));
  }

  public async findAll(shopId: string): Promise<BaseProduct[]> {
    return this.find({
      where: [this.whereShopIs(shopId)],
      orderBy: [{field: 'title', direction: 'asc'}],
    });
  }

  public async findShopVisible(shopId: string): Promise<BaseProduct[]> {
    return this.find({
      where: [this.whereShopIs(shopId), {field: 'status', operator: 'in', value: shopVisibleStatuses}],
      orderBy: [{field: 'title', direction: 'asc'}],
    });
  }

  public async listByType<T extends BaseProduct>(shopId: string, type: PropType<T, 'type'>): Promise<T[]> {
    return this.find({
      where: [this.whereShopIs(shopId), {field: 'type', operator: '==', value: type}],
    }) as Promise<T[]>;
  }

  public async getAsType<T extends BaseProduct>(id: string): Promise<T> {
    return this.get(id) as Promise<T>;
  }

  public async findActiveOfTypeForShop<T extends BaseProduct>(
    shopId: string,
    type: PropType<T, 'type'>,
    timeZone: string,
  ): Promise<T[]> {
    return this.find({
      where: [this.whereShopIs(shopId), {field: 'type', operator: '==', value: type}],
    }).then(products => products.filter(p => getProductStatus(p, timeZone) === 'active')) as Promise<T[]>;
  }

  public async findActiveInStock(shopId: string, timeZone: string): Promise<BaseProduct[]> {
    return this.find({
      where: [this.whereShopIs(shopId), {field: 'inventory', operator: '>', value: 0}],
    }).then(products => products.filter(p => getProductStatus(p, timeZone) === 'active'));
  }

  public async findWithStatus(shopId: string, status: ProductStatus[], timeZone: string): Promise<BaseProduct[]> {
    return this.find({
      where: [this.whereShopIs(shopId)],
    }).then(products => products.filter(p => status.includes(getProductStatus(p, timeZone) as ProductStatus)));
  }

  public async findForEvent(event: string): Promise<BaseProduct[]> {
    return this.find({
      where: [
        {field: 'status', operator: '==', value: 'active'},
        {field: 'eventTags', operator: 'array-contains', value: event},
      ],
      orderBy: [{field: 'updatedAt', direction: 'desc'}],
    });
  }

  public async findForReview(): Promise<BaseProduct[]> {
    const baseProducts = await this.find({
      where: [
        {field: 'approval.reviewed', operator: '==', value: false},
        {field: 'status', operator: '==', value: 'active'},
      ],
      orderBy: [{field: 'createdAt', direction: 'desc'}],
    });

    return  baseProducts.filter(p => p.images?.length > 0 && !!p.fulfillment);
  }
}
