export const kebabCase = (str: string): string => (str.match(/[a-zA-Z0-9]+/g) || []).join('-').toLowerCase() || 'na';

export const stripHtml = (str: string): string => str ? str.replace(/\<.*?\>/g, '').trim() : '';

export const  slugify = (str) => {
  if (!str) return '';

  return String(str)
    .normalize('NFKD') // split accented characters into their base characters and diacritical marks
    .replace(/[\u0300-\u036f]/g, '') // remove diacritical marks
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9\s-]/g, '-') // replace any non-alphanumeric characters (excluding spaces and hyphens) with a hyphen
    .replace(/\s+/g, '-') // replace spaces with hyphens
    .replace(/-+/g, '-') // remove consecutive hyphens
    .replace(/^-+|-+$/g, ''); // remove leading and trailing hyphens
}