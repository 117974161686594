import { useAppSelector } from '../hooks';

const findHasPaidSub = () => {
  const { account } = useAppSelector(state => ({
    account: state.shops.account,
  }));

  //Plus, Pro, Pro v2, Basic, Business, Dollar
  const paidTiers = [
    '1IB2ACPnCLFm4lPjEJgM',
    '2KHC6hiFiFjVUvdDH0lD',
    'UqcnzrPWHT2fgCuTyDbz',
    'SQDLROyx5tJHhfNqB21h',
    'gBio8AP9UCXjEITDNWh3',
    'uNnuPUOpRodYpJRJfurEU',
  ];
  const tierId = account?.subscription?.plan?.tier?.id;

  // Marketplace Tier
  if (account?.tier?.id === 'ktIzir4hddvs7EcIJ75q') {
    return true;
  } else if ((account?.subscription?.status === 'active' || account?.subscription?.status === 'trial') && tierId) {
    return paidTiers.includes(tierId);
  } else return false;
};

export default findHasPaidSub;
