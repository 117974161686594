import React from 'react';
import { ButtonV2 } from '@castiron/components';
import { CircularProgress, Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export interface StickyFooterProps {
  isFinalStep?: boolean;
  showSkipButton?: boolean;
  onNextClick?: () => void;
  onBackClick?: () => void;
}

interface Props extends StickyFooterProps {
  step: number;
  isLoading?: boolean;
  isInternational?: boolean;
  nextStep: () => void;
  prevStep: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: '16px 0px',
  },
  leftButton: {
    marginRight: 4,
  },
  rightButton: {
    marginLeft: 4,
  },
  skipButton: {
    color: theme.branding.v2.gray[900],
    padding: 'auto 0px',
  },
  spinner: {
    color: theme.branding.v2.gray[0],
  },
}));

const OnboardingFooter: React.FC<Props> = (props: Props) => {
  const {
    step,
    isFinalStep,
    showSkipButton,
    isLoading,
    onNextClick,
    nextStep,
    onBackClick,
    prevStep,
    isInternational,
  } = props;
  const classes = useStyles();

  const handlePrevStep = () => {
    if (!!onBackClick) {
      onBackClick();
    }
    window.scrollTo(0, 0);
    prevStep();
  };

  const handleNextStep = () => {
    window.scrollTo(0, 0);
    onNextClick();
  };

  return (
    <Grid container justify="space-between" alignItems="center" wrap="nowrap" className={classes.container}>
      {showSkipButton && (
        <Grid container item xs={3} justify="flex-start" onClick={nextStep}>
          <ButtonV2 variant="text" className={classes.skipButton} disabled={isLoading}>
            Skip
          </ButtonV2>
        </Grid>
      )}
      <Grid container item xs={showSkipButton ? 9 : 12} justify="flex-end" wrap="nowrap">
        {step !== 1 && (
          <ButtonV2 className={classes.leftButton} variant="outlined" onClick={handlePrevStep} disabled={isLoading}>
            Back
          </ButtonV2>
        )}
        <ButtonV2
          type="submit"
          className={classes.rightButton}
          variant="contained"
          color="primary"
          onClick={handleNextStep}
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress className={classes.spinner} size={24} />
          ) : isFinalStep ? (
            'Finish'
          ) : isInternational ? (
            'Back to Castiron'
          ) : (
            'Continue'
          )}
        </ButtonV2>
      </Grid>
    </Grid>
  );
};

export default OnboardingFooter;
