import React from 'react';
import { useHistory } from 'react-router';
import { Grid, Theme, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import _ from 'lodash';
import { Button } from '@castiron/components';
import { useTracking } from '@castiron/utils';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { openModal } from '../../../store/reducers/modalConductor';
import { ArrowForward } from '@material-ui/icons';
import moment from 'moment';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { showFirstMonthPromoBlock } from '../../ReferralPrompts/PromoBlock';

const useStyles = makeStyles((theme: Theme) => ({
  arrowForward: {
    height: 24,
    marginLeft: 4,
    width: 24,
  },
  trialExpirationBanner: {
    '& button': {
      padding: 0,
      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
  },
  infoOutlined: {
    color: theme.branding.orange.primary,
    height: 20,
    marginRight: 10,
    width: 20,
  },
  trialExpirationBannerContainer: {
    backgroundColor: theme.branding.yellow.light,
    minHeight: 56,
    padding: '0px 72px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 24px',
    },
  },
}));

const TrialExpirationBanner: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { trackEvent } = useTracking();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { account, shop, subscription, tier } = useAppSelector(state => ({
    account: state.shops.account,
    shop: state.shops.shop,
    subscription: state.shops.account.subscription,
    tier: state.shops.account.tier,
  }));

  const trialDaysLeft = moment.unix(subscription?.trialEndDate).diff(moment(), 'days') + 1;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const showFirstMonthPromo = showFirstMonthPromoBlock(account);

  const handleClick = async () => {
    trackEvent('Trial Expiration Banner Clicked', {
      shop: _.pick(shop, ['id', 'websiteUrl', 'businessName', 'email', 'checklistCompletions']),
    });
    history.push('/store/plans');
  };

  const openChangePlanModal = () => {
    trackEvent('Shop View Upgrade Modal', {
      shopId: shop.id,
      location: 'trialExpirationBanner',
      tier: tier,
      currentPlan: subscription?.plan.name,
    });
    dispatch(
      openModal({
        modalType: 'CHANGE_PLANS_MODAL',
        modalProps: {
          open: true,
          ...(showFirstMonthPromo ? { couponCode: 'dqPgpfQz' } : {}),
          step: account?.isCastironPlus() ? 1 : 0,
          selectedPlan: {
            plan: subscription?.plan,
            price: subscription?.price,
          },
        },
      }),
    );
  };

  return (
    <Grid
      container
      item
      className={classes.trialExpirationBannerContainer}
      xs={12}
      style={showFirstMonthPromo ? { backgroundColor: theme.branding.v2.green[50] } : {}}
    >
      <Grid
        container
        item
        justify="space-between"
        alignItems="center"
        className={classes.trialExpirationBanner}
        xs={12}
      >
        <Grid container item wrap="nowrap" alignItems="center" xs={8} sm={6}>
          <InfoOutlined
            className={classes.infoOutlined}
            style={showFirstMonthPromo ? { color: theme.branding.v2.green[500] } : {}}
          />
          {isMobile ? (
            <Typography variant="body2">
              <b>{trialDaysLeft} days</b> left in your trial.
            </Typography>
          ) : (
            <Typography variant="body2">
              You have <b>{trialDaysLeft} days</b> left in your trial.
            </Typography>
          )}
        </Grid>
        <Grid container item justify="flex-end" xs={4} sm={6}>
          <Button onClick={openChangePlanModal} variant="text">
            <Typography
              variant="body2"
              style={{ color: showFirstMonthPromo ? theme.branding.v2.green[500] : theme.branding.blue.primary }}
            >
              {showFirstMonthPromo ? 'Upgrade Now for $1' : isMobile ? 'Upgrade' : 'Upgrade Now'}
            </Typography>
            <ArrowForward
              className={classes.arrowForward}
              style={showFirstMonthPromo ? { color: theme.branding.v2.green[500] } : {}}
            />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TrialExpirationBanner;
