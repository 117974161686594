import firebase from 'firebase/compat/app';
import _firestore from '@google-cloud/firestore';
import moment from 'moment';
import _ from 'lodash';
import { BaseDocument, BaseRepository, FieldFunctions } from '../base/repository';
import { removeEmpty } from '../_lib/removeEmpty';
import { areSubscriptionFeaturesActive, PlanDiscount, Subscription } from '../subscription';
import dataOnly from '../_lib/dataOnly';
import { TakeRateLevel, Tier } from '../tier';
import { Address } from '../address';
import { ServiceAreaLocation, ThemeNameOption } from '../shop';
import { Attribution } from '../attribution';
import { TaxonomyCategory } from '../taxonomy';
import { MessageFrom, MessageTo, MessageType } from '../message';
import { RequireExactlyOne } from 'type-fest';

const PAYMENT_HISTORY_COLLECTION = 'payment_history';
const MESSAGE_HISTORY_COLLECTION = 'message_history';

const PLUS_TIERS = ['Plus', 'Dollar', 'Marketplace'];

const TAKE_RATE_TIERS = ['Marketplace'];

export interface MessageHistory extends BaseDocument<MessageHistory> {
  messageId?: string;
  sendAt: number;
  wasSent: boolean;
  channel: 'email' | 'sms';
  to: RequireExactlyOne<MessageTo, 'shopId' | 'customerIds' | 'castiron' | 'castironInternal' | 'eventAttendees'>;
  from: RequireExactlyOne<MessageFrom, 'castiron' | 'castironInternal' | 'shopId' | 'customerId'>;
  subject?: string;
  body?: string;
  type: MessageType;
  status?: 'deleted';
}

export interface PaymentHistoryStripe {
  invoiceId: string;
}

export interface PaymentHistoryIntegrations {
  stripe?: PaymentHistoryStripe;
}

export interface PaymentHistory extends BaseDocument<PaymentHistory> {
  chargeDate: number;
  subscription: Subscription;
  paymentType: 'credit-card' | 'ACH';
  status: 'succeeded' | 'failed';
  amount: number;
  discount?: PlanDiscount;
  integrations?: PaymentHistoryIntegrations;
}

export interface Recipient {
  customerId: string;
  name: string;
  email?: string;
  phoneNumber?: string;
}

interface SendGridMessageIntegration {
  messageId: string;
  batchId?: string;
}

interface MessageLogIntegrations {
  sendgrid: SendGridMessageIntegration;
}

export interface MessageLog {
  to: Recipient[];
  createdAt: number;
  scheduledToSendAt?: number;
  sentAt: number;
  subject?: string;
  body: string;
  integrations: MessageLogIntegrations;
}

export interface SendGridConfig {
  contactListId: string;
  unsubscribeGroupId: number;
  senderId: number;
}

export type BusinessStage =
  | 'brand-new' // onboarding v3 & v4
  | 'no-online-ordering' // onboarding v3
  | 'selling-online-unhappy' // onboarding v3
  | 'compairing' // onboarding v3
  | 'just-looking' // onboarding v3
  | 'part-time-entrepreneur' // onboarding v4
  | 'full-time-entrepreneur' // onboarding v4
  | 'enterprise-business'; // onboarding v4

export type SellingLocationOption = 'online' | 'local-markets' | 'retail-store' | 'other-pop-up';
export type BusinessGoal =
  | 'custom-order'
  | 'manage-business'
  | 'online-shopping'
  | 'marketing-automation'
  | 'other'
  | 'website';

export type PersonaTypes =
  | 'custom-cookier'
  | 'custom-cake'
  | 'baker-or-dessert'
  | 'charcuterie'
  | 'meal-prepper'
  | 'caterer'
  | 'chef'
  | 'restaurant'
  | 'other';

export type ProductTypes =
  //custom cookiers
  | 'custom-cookies'
  | 'decorating-class'
  | 'decorating-kit'
  | 'cookie-set-presales'
  | 'macarons'
  | 'drop-cookies'
  //generic
  | 'other';

export interface AccountTier {
  id: string;
  name: string;
  castironTakeRate: number;
  takeRateLevels?: TakeRateLevel[];
  payoutFrequency: 'daily' | 'weekly' | 'monthly';
}

export interface AccountMetrics {
  numSales?: number;
  totalRevenue?: number;
  lastSaleDate?: number;
  numCustomers?: number;
  numSubscribers?: number;
  numActiveProducts?: number;
  totalTips?: number;
}

export interface HubSpotIntegration {
  id: string;
}

export interface ConvertKitIntegration {
  id: string;
}

export interface SendGridIntegration {
  config: SendGridConfig;
}

export interface StripeIntegration {
  accountId: string;
  customerId?: string;
  testClockId?: string;
  locationId?: string;
  lastStripeConnectionAttempt?: number;
}

export interface GoogleCloudIntegration {
  certificate?: string;
}

export interface Integrations {
  hubspot?: HubSpotIntegration;
  sendgrid?: SendGridIntegration;
  stripe?: StripeIntegration;
  google?: GoogleCloudIntegration;
  convertKit?: ConvertKitIntegration;
}

export interface MigrationInformation {
  existingShopUrl?: string;
  instagramUrl?: string;
  facebookUrl?: string;
}

export interface ContactOptions {
  instagramUrl?: string;
  facebookUrl?: string;
  mobilePhone?: string;
}

export interface OnboardingStep {
  version?: number;
  step?: number;
}

export interface BusinessInfo {
  instagramHandle?: string;
}

export interface OnboardingFulfillmentOption {
  type: 'pickup' | 'delivery' | 'local-shipping' | 'nationwide-shipping';
  addToShop: boolean;
  fulfillmentFee?: number;
}

export interface OnboardingQuestions {
  //legacy
  persona?: PersonaTypes;
  onboardingStep?: OnboardingStep;
  orderVolume?: string;
  salesMethod?: string[];
  categories?: string[];
  contactOptions?: ContactOptions;
  migrationInformation?: MigrationInformation;
  goal?: BusinessGoal[];
  sellingLocations?: SellingLocationOption[];
  businessStage?: BusinessStage;
  productTypes?: ProductTypes;
  castironIntent?: 'website' | 'operations' | 'engagement';
  fulfillmentOptions?: OnboardingFulfillmentOption[];
  //current - common
  businessInfo?: BusinessInfo;
  theme?: ThemeNameOption;
  //current - castiron
  taxonomy?: TaxonomyCategory[];
  newProducts?: string[];
  serviceArea?: ServiceAreaLocation[];
  //current - nourysh
  challenges?: string[];
  products?: string[];
  markets?: string[];
}

export interface OnboardingModals {
  contactsModalShown?: boolean;
  couponModalShown?: boolean;
  fulfillmentModalShown?: boolean;
  paymentModalShown?: boolean;
  productModalShown?: boolean;
}

interface CommsDefaults {
  sendCustomerFulfilledConfirmation: boolean;
  sendMeFulfilledConfirmation: boolean;
}

export interface MessagingPreferences {
  sms?: {
    enabled?: boolean;
    smsPhoneNumber?: string;
    verificationCode?: string;
  };
}

interface Tolt {
  referralId?: string;
  referralEmail?: string;
  affiliateId?: string;
  affiliateEmail?: string;
}

interface AccountConfig {
  commsDefaults?: CommsDefaults;
  messagingPreferences?: MessagingPreferences;
  showFirstMonthPromo?: boolean;
  tolt?: Tolt;
}

interface GoogleMerchantCenter {
  id?: string;
  exclude?: boolean;
}

export interface Account extends BaseDocument<Account> {
  status?: 'active' | 'inactive' | 'deleted' | 'onboarding';
  lastStripeConnectionAttempt?: number; // TODO: Migrate to Integrations
  stripeAccountId?: string; // TODO: Migrate to Integrations
  googleMerchantCenter?: GoogleMerchantCenter;
  isReady?: boolean;
  sendGridConfig?: SendGridConfig; // TODO: Migrate to Integrations
  tier: AccountTier;
  legacyTier?: AccountTier;
  businessStage?: BusinessStage; // TODO: Migrate to OnboardingQuestions
  metrics?: AccountMetrics;
  orderNumberCounter: number;
  integrations?: Integrations;
  onboardingQuestions?: OnboardingQuestions;
  onboardingModals?: OnboardingModals;
  subscription?: Subscription;
  billingAddress?: Address;
  phoneNumber?: string;
  config?: AccountConfig;

  addPaymentHistory?: (paymentHistory: PaymentHistory) => Promise<PaymentHistory>;
  getPaymentHistory?: () => Promise<PaymentHistory[]>;

  addMessageHistory?: (message: MessageHistory) => Promise<MessageHistory>;
  getMessageHistory?: (
    channel: 'email' | 'sms',
    num: number,
    orderBy: 'subject' | 'body' | 'sendAt' | 'createdAt',
    startAfter?: string,
    type?: string,
  ) => Promise<MessageHistory[]>;
  getMessageHistoryCount?: (channel: 'email' | 'sms') => Promise<number>;
  updateMessageHistory?: (
    messageId: string,
    messageProps: Partial<MessageHistory> | Record<string, any>,
  ) => Promise<MessageHistory>;

  /* subscription functions */
  areSubscriptionFeaturesActive?: () => boolean;
  /* utility functions */
  isNewUnsubscribedUser?: () => boolean;
  isPaidSubscriptionEnded?: () => boolean;
  isInTrial?: () => boolean;
  isTrialCompleted?: () => boolean;
  isLegacyTrialCompleted?: () => boolean;
  hasLegacySubscription?: () => boolean;
  isCastironPlus?: () => boolean;
  hasCastironTakeRate?: () => boolean;
  attribution?: Attribution;
}

export class AccountRepository extends BaseRepository<Account> {
  constructor(firestore: firebase.firestore.Firestore | _firestore.Firestore, fieldFunctions?: FieldFunctions) {
    super(firestore, 'accounts', fieldFunctions);
  }

  public async findByStripeId(stripeAccountId: string): Promise<Account | null> {
    const results = await this.find({
      where: [{ field: 'stripeAccountId', operator: '==', value: stripeAccountId }],
    });

    return this.firstOrNull(results);
  }

  public async findBySendGridUnsubscribeGroup(unsubscribeGroupId: number): Promise<Account | null> {
    const results = await this.find({
      where: [{ field: 'sendGridConfig.unsubscribeGroupId', operator: '==', value: unsubscribeGroupId }],
    });

    return this.firstOrNull(results);
  }

  public async findArtisanActiveAccounts(): Promise<Account[]> {
    const sixtyDaysAgo = moment()
      .subtract(60, 'days')
      .unix();
    return this.find({
      where: [{ field: 'metrics.numSales', operator: '>=', value: 10 }],
    }).then(accounts =>
      accounts.filter(a => a.metrics.lastSaleDate >= sixtyDaysAgo && a.metrics.numActiveProducts >= 2),
    );
  }

  public async updateMetrics(id: string, metrics: AccountMetrics) {
    return this.updateProps(
      id,
      removeEmpty({
        'metrics.lastSaleDate': metrics.lastSaleDate,
        'metrics.numCustomers': metrics.numCustomers && this.fieldFunctions.increment(metrics.numCustomers),
        'metrics.numSubscribers': metrics.numSubscribers && this.fieldFunctions.increment(metrics.numSubscribers),
        'metrics.numProducts': metrics.numActiveProducts && this.fieldFunctions.increment(metrics.numActiveProducts),
        'metrics.numSales': metrics.numSales && this.fieldFunctions.increment(metrics.numSales),
        'metrics.totalRevenue': metrics.totalRevenue && this.fieldFunctions.increment(metrics.totalRevenue),
        'metrics.totalTips': metrics.totalRevenue && this.fieldFunctions.increment(metrics.totalTips),
      }),
    );
  }

  public async findSubsExpiring(days: number) {
    const nowM = moment
      .utc()
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0);
    const now = nowM.unix();
    const startM = nowM.add(days, 'days');
    const start = startM.unix();
    const end = startM.add(1, 'days').unix();

    console.debug(`Looking for subs that expire in [${days}] days`, {
      now,
      start,
      end,
    });

    return this.find({
      where: [
        { field: 'subscription.trialEndDate', operator: '>=', value: start },
        { field: 'subscription.trialEndDate', operator: '<', value: end },
      ],
    });
  }

  public async addPaymentHistory(id: string, paymentHistory: PaymentHistory): Promise<PaymentHistory> {
    const createdAt = moment().unix();
    const ph = {
      ...paymentHistory,
      createdAt,
    };

    return this.collection()
      .doc(id)
      .collection(PAYMENT_HISTORY_COLLECTION)
      .add(ph)
      .then(doc => ({
        ...ph,
        id: doc.id,
      }));
  }

  public async getPaymentHistory(id: string): Promise<PaymentHistory> {
    return this.collection()
      .doc(id)
      .collection(PAYMENT_HISTORY_COLLECTION)
      .get()
      .then(c =>
        c.empty
          ? []
          : c.docs.map(doc => ({
              ...doc.data(),
              id: doc.id,
            })),
      );
  }

  public async addMessageHistory(id: string, message: MessageHistory): Promise<MessageHistory> {
    const createdAt = moment().unix();
    const mh = {
      ...message,
      createdAt,
    };

    return this.collection()
      .doc(id)
      .collection(MESSAGE_HISTORY_COLLECTION)
      .add(mh)
      .then(doc => ({
        ...mh,
        id: doc.id,
      }));
  }

  public async getMessageHistory(
    id: string,
    channel: 'email' | 'sms',
    num: number,
    orderBy: 'subject' | 'body' | 'sendAt' | 'createdAt',
    startAfter?: string,
  ): Promise<MessageHistory[]> {
    let response = this.collection()
      .doc(id)
      .collection(MESSAGE_HISTORY_COLLECTION)
      .where('createdAt', '>=', 1719460800)
      .where('channel', '==', channel)
      .where('wasSent', '==', true)
      .where('type', 'in', [
        'email_announcement',
        'presale_announcement',
        'product_announcement',
        'sms_announcement',
        'email_announcement_nourysh',
        'product_announcement_nourysh',
      ])
      .orderBy('createdAt', 'desc');

    if (orderBy === 'subject' || orderBy === 'body') {
      response = response.orderBy(orderBy, 'asc');
    } else if (orderBy === 'sendAt') {
      response = response.orderBy('sendAt', 'desc');
    }
    response = response.limit(num);

    if (startAfter) {
      const startAfterSnapshot = await this.collection()
        .doc(id)
        .collection(MESSAGE_HISTORY_COLLECTION)
        .doc(startAfter)
        .get();
      response = response.startAfter(startAfterSnapshot);
    }

    const messageDocs = await response.get();
    const messages = messageDocs.docs.map(doc => ({ ...doc.data(), id: doc.id }));

    return messages;
  }

  public async getMessageHistoryCount(id: string, channel: 'email' | 'sms'): Promise<number> {
    return (
      await this.collection()
        .doc(id)
        .collection(MESSAGE_HISTORY_COLLECTION)
        .where('createdAt', '>=', 1719460800)
        .where('channel', '==', channel)
        .where('wasSent', '==', true)
        .where('type', 'in', [
          'email_announcement',
          'presale_announcement',
          'product_announcement',
          'sms_announcement',
          'email_announcement_nourysh',
          'product_announcement_nourysh',
        ])
        .get()
    ).size;
  }

  public async updateMessageHistory(
    shopId: string,
    messageId: string,
    messageProps: Partial<MessageHistory> | Record<string, any>,
  ): Promise<void> {
    const updatedAt = moment().unix();
    return this.collection()
      .doc(shopId)
      .collection(MESSAGE_HISTORY_COLLECTION)
      .doc(messageId)
      .update({ ...messageProps, updatedAt })
      .then(() => {});
  }

  protected enrichWith(): any {
    return {
      _repository: this,
      addPaymentHistory(paymentHistory: PaymentHistory) {
        return this._repository.addPaymentHistory(this.id, paymentHistory);
      },
      getPaymentHistory() {
        return this._repository.getPaymentHistory(this.id);
      },
      addMessageHistory(message: MessageHistory) {
        return this._repository.addMessageHistory(this.id, message);
      },
      getMessageHistory(
        channel: 'email' | 'sms',
        num: number,
        orderBy: 'subject' | 'body' | 'sendAt' | 'createdAt',
        startAfter?: string,
      ) {
        return this._repository.getMessageHistory(this.id, channel, num, orderBy, startAfter);
      },
      getMessageHistoryCount(channel: 'email' | 'sms') {
        return this._repository.getMessageHistoryCount(this.id, channel);
      },
      updateMessageHistory(messageId: string, messageProps: Partial<MessageHistory> | Record<string, any>) {
        return this._repository.updateMessageHistory(this.id, messageId, messageProps);
      },
      areSubscriptionFeaturesActive() {
        return areSubscriptionFeaturesActive(this.subscription);
      },
      isNewUnsubscribedUser() {
        return this.tier?.name === 'Unpaid' && !this.subscription;
      },
      isPaidSubscriptionEnded() {
        return (
          this.subscription?.cancellation !== undefined && this.subscription?.cancellation?.reason !== 'trial-expired'
        );
      },
      isInTrial() {
        return this.subscription?.status === 'trial';
      },
      isTrialCompleted() {
        return (
          (this.subscription?.status === 'canceled' && this.subscription?.cancellation?.reason === 'trial-expired') ||
          (this.subscription?.isTrialOver && this.subscription?.status === 'inactive')
        );
      },
      isLegacyTrialCompleted() {
        return this.subscription?.isTrialOver;
      },
      hasLegacySubscription() {
        return this.tier?.name === 'Founder' || this.tier?.name === 'Starter' || this.legacyTier;
      },
      isCastironPlus() {
        return PLUS_TIERS.includes(this.tier?.name);
      },
      hasCastironTakeRate() {
        return TAKE_RATE_TIERS.includes(this.tier?.name);
      },
      data() {
        return dataOnly(_.omit(this, ['_repository']));
      },
    };
  }
}

export const toAccountTier = (tier: Tier): AccountTier =>
  _.pick(tier, ['id', 'name', 'castironTakeRate', 'takeRateLevels', 'payoutFrequency']) as AccountTier;
