import React, { useEffect, useState, useCallback } from 'react';
import _ from 'lodash';
import { Chip, Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import { SimpleAddressInput, Typography } from '@castiron/components';
import { ServiceAreaLocation } from '@castiron/domain';
import { useTracking } from '@castiron/utils';
import { accountRepository } from '../../../../domain';
import { getService } from '../../../../firebase';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { trackHubSpotContactPage } from '../../../../lib/trackHubSpotContactEvent';
import { updateShopAction } from '../../../../store/reducers/shops';
import { StickyFooterProps } from '../OnboardingFooter';

const locationSearchService = getService('locations', 'search');

interface Props {
  step: number;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  setHeader?: (header: string) => void;
  setSubHeader?: (subHeader: string) => void;
  setStickyFooterProps?: (props: StickyFooterProps) => void;
  nextStep?: () => void;
  onFinishOnboarding?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  chipDeleteIcon: {
    color: theme.branding.v2.gray[800],
    margin: 0,
    width: 16,
    height: 16,
  },
  container: {
    margin: '24px 0px 64px',
    gap: 24,
  },
  displayChips: {
    gap: 4,
  },
  inputError: {
    '& div': {
      '& fieldset': {
        borderColor: theme.palette.error.main,
      },
    },
  },
  input: {
    fontSize: 16,
    fontWeight: 400,
    padding: 12,
  },
  inputRoot: {
    minHeight: 56,
    width: '100%',
    border: 'none',
    borderRadius: '12px',
  },
  locationChip: {
    gap: 8,
    padding: '4px 12px',
    height: 28,
    backgroundColor: theme.branding.v2.gray[100],
    '& .MuiChip-label': {
      padding: 0,
    },
  },
}));

const ServiceArea: React.FC<Props> = (props: Props) => {
  const { step, nextStep, setLoading, setHeader, setStickyFooterProps, setSubHeader, onFinishOnboarding } = props;
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();
  const classes = useStyles();

  const { account, shop } = useAppSelector(state => ({
    account: state.shops.account,
    shop: state.shops.shop,
  }));

  const [error, setError] = useState('');
  const [displayValues, setDisplayValues] = useState([]);
  const [locationValues, setLocationValues] = useState([]);

  const onAddressChange = useCallback(
    location => {
      setError('');

      let parsedLocation;
      const postalCodeInput = location.fullAddress.split(' ').find(word => /\d/.test(word));

      if (postalCodeInput !== undefined) {
        const cleanPostalCode = _.replace(postalCodeInput, ',', '');
        parsedLocation = `${cleanPostalCode}, ${location.city}, ${location.region}`;
      } else {
        parsedLocation = _.replace(location.fullAddress, ', USA', '');
      }

      setLocationValues(arr => [
        ...arr,
        {
          ...location,
          fullAddress: parsedLocation,
        },
      ]);
      setDisplayValues(arr => {
        const exists = arr.includes(parsedLocation);
        if (!exists) {
          return [parsedLocation, ...arr];
        } else {
          setError(`Location has already been selected: ${parsedLocation}`);
          return arr;
        }
      });
    },
    [displayValues, locationValues, error],
  );

  const handleLocationDeletion = deletedLocation => {
    const newDisplayValues = displayValues.filter(dv => dv !== deletedLocation);
    const newLocationValues = locationValues.filter(lv => lv.fullAddress !== deletedLocation);
    setDisplayValues(newDisplayValues);
    setLocationValues(newLocationValues);
  };

  const submit = async () => {
    setLoading(true);
    setError('');

    if (!_.isEmpty(locationValues)) {
      try {
        let fullLocations: ServiceAreaLocation[] = [];

        const postalCodes = await Promise.all(
          locationValues.flatMap(async loc => {
            if (!!loc.postalCode) {
              fullLocations.push({
                postalCodes: loc.postalCode,
                display: loc.fullAddress,
              });

              return loc.postalCode;
            } else if (!!loc.city && !!loc.region) {
              const esLocations = await locationSearchService({
                filter: {
                  city: loc.city.replaceAll('-', ' '),
                  regionAbv: loc.region,
                },
              });

              const esPostalCodes = esLocations.locations.map(doc => doc.postalCode);

              fullLocations.push({
                postalCodes: esPostalCodes,
                display: loc.fullAddress,
              });
              return esPostalCodes;
            } else {
              console.debug('Could not parse postal codes for location: ', loc);
              fullLocations.push({
                postalCodes: [],
                display: loc.fullAddress,
              });
            }
          }),
        );

        const cleanPostalCodes = _.uniq(_.flattenDeep(postalCodes));

        await Promise.resolve(
          dispatch(
            updateShopAction({
              shop: {
                ...shop,
                serviceArea: {
                  ...shop?.serviceArea,
                  postalCodes: cleanPostalCodes,
                  locations: fullLocations,
                },
              },
            }),
          ),
        );

        trackEvent('Onboarding Service Area Selected', { serviceArea: postalCodes });

        await accountRepository.updateProps(account.id, {
          'onboardingQuestions.serviceArea': fullLocations,
        });
        trackHubSpotContactPage(
          {
            email: shop.email,
          },
          `/signup/info/${step}`,
        );
      } catch (err) {
        console.error('Error submitting locations: ', err);
      }
    } else {
      setError('You need at least one service area');
      setLoading(false);
      return;
    }

    setLoading(false);
    onFinishOnboarding();
  };

  useEffect(() => {
    setHeader('📍 What is your service area?');
    setSubHeader(
      'Let customers know where your business provides local deliveries and other services. You can customize this more later.',
    );
    setStickyFooterProps({
      onNextClick: submit,
      isFinalStep: true,
    });

    return () => {
      setStickyFooterProps(undefined);
      setHeader('');
      setSubHeader('');
    };
  }, [locationValues]);

  useEffect(() => {
    if (!_.isEmpty(account?.onboardingQuestions?.serviceArea)) {
      //reload options from onboarding Qs
      const displays = account.onboardingQuestions.serviceArea.map(area => area.display);
      const locationObjs = account.onboardingQuestions.serviceArea.map(area => ({
        fullAddress: area.display,
        postalCode: area.postalCodes,
      }));

      setDisplayValues(displays);
      setLocationValues(locationObjs);
    } else if (shop?.physicalAddress?.city && shop?.physicalAddress?.region && _.isEmpty(displayValues)) {
      //add business address city to display values if it exists
      const existingAddress = `${shop.physicalAddress.city}, ${shop?.physicalAddress?.region}`;
      setDisplayValues([existingAddress]);
      setLocationValues([
        {
          fullAddress: existingAddress,
          city: shop.physicalAddress.city,
          region: shop.physicalAddress.region,
        },
      ]);
    }
  }, [shop]);

  return (
    <Grid container direction="column" className={classes.container}>
      <SimpleAddressInput onAddressChange={onAddressChange} error={error} label="Location" />
      <Grid container item direction="column" style={{ gap: 8 }}>
        <Typography variant="subtitle2">Selected Service Areas</Typography>
        <Grid container item direction="row" className={classes.displayChips}>
          {displayValues.map(location => (
            <Chip
              label={<Typography variant="button2">{location}</Typography>}
              onDelete={() => handleLocationDeletion(location)}
              deleteIcon={<ClearOutlinedIcon className={classes.chipDeleteIcon} />}
              className={classes.locationChip}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ServiceArea;
